import { values, compact, defaults } from 'lodash-es'

import {
  DATASET,
  ROUTER_DATASET,
} from '@wix/wix-data-client-common/src/datasetTypes'
import {
  DETAILS_DATASET_ROLE,
  DROPDOWN_OPTIONS_ROLE,
  DROPDOWN_ROLE,
} from '@wix/wix-data-client-common/src/connection-config/roles'
import * as modes from '@wix/wix-data-client-common/src/dataset-configuration/readWriteModes'
import * as defaultDatasetConfiguration from '@wix/wix-data-client-common/src/dataset-configuration/defaults'
import { hasDynamicFilter } from '../filter-resolvers'
import { PRIMARY, REGULAR, UNCONFIGURED } from '../data/sequenceType'
import appContext from '../viewer-app-module/DataBindingAppContext'
import { AppError } from '../logger'

const { WRITE } = modes
const COMP_ROLES_FOR_UNIQUE_VALUES = [DROPDOWN_OPTIONS_ROLE]
const COMP_ROLES_TO_BLOCK_UNIQUE_VALUES = [DROPDOWN_ROLE]
const PROP_FOR_UNIQUE_VALUES = 'value'

const getDatasetSequenceType = ({
  collectionId,
  datasetHasDynamicFilter,
  datasetIsDeferred,
  datasetIsRouter,
  datasetIsWriteOnly,
}) => {
  if (!collectionId) return UNCONFIGURED
  return !datasetHasDynamicFilter &&
    !datasetIsDeferred &&
    !datasetIsRouter &&
    !datasetIsWriteOnly
    ? PRIMARY
    : REGULAR
}

const getStaticDatasetConfig = (dataset, datasetType, connections) => {
  const {
    readWriteType,
    deferred,
    filter,
    collectionName: collectionId,
  } = dataset
  const datasetIsWriteOnly = readWriteType === WRITE
  const datasetIsMaster = connections.some(
    ({ role }) => role === DETAILS_DATASET_ROLE,
  )
  const datasetIsRouter = datasetType === ROUTER_DATASET
  const datasetIsDeferred =
    Boolean(deferred) &&
    !(datasetIsMaster || datasetIsRouter || datasetIsWriteOnly)

  const datasetHasDynamicFilter = filter && hasDynamicFilter(filter)

  const sequenceType = getDatasetSequenceType({
    collectionId,
    datasetHasDynamicFilter,
    datasetIsDeferred,
    datasetIsRouter,
    datasetIsWriteOnly,
  })

  //TODO: migrate these calculations in redux store, or get rid of redux for dataset settings
  return {
    sequenceType,
    datasetIsWriteOnly,
    datasetIsMaster,
    datasetIsRouter,
    datasetIsDeferred,
    datasetHasDynamicFilter,
  }
}

const getFieldKeysForUniqueValues = connections => {
  const compIdsToRolesMap = connections.reduce(
    (acc, { config, role, compId }) => {
      const fieldName = config?.properties?.[PROP_FOR_UNIQUE_VALUES]?.fieldName
      if (!fieldName) return acc
      const mapKey = `${compId}-${fieldName}`

      if (COMP_ROLES_TO_BLOCK_UNIQUE_VALUES.includes(role)) {
        // If comp has two connections to value and options it might be reference usecase. We shouldn't use distinct in this case
        // So exclude it from this flow and use fallback fetch from adapter
        acc.set(mapKey, null)
        return acc
      }

      if (COMP_ROLES_FOR_UNIQUE_VALUES.includes(role) && !acc.has(mapKey)) {
        acc.set(mapKey, fieldName)
      }

      return acc
    },
    new Map(),
  )

  return {
    uniqueFieldValues: compact(Array.from(compIdsToRolesMap.values())),
  }
}

const completeControllerConfigs = datasetConfigs => {
  //TODO: Split completeControllerConfig. Use new format for dataFetcher instead
  const datasetTypes = values({
    DATASET,
    ROUTER_DATASET,
  })

  return datasetConfigs.map(datasetConfig => {
    const {
      id,
      type,
      collectionId,
      filter,
      sort,
      pageSize,
      readWriteType,
      includes,
      nested,
      deferred,
      connections,
      dataIsInvalidated,
      updatedCompIds,
      dynamicPageData,
      cursor,
    } = datasetConfig
    if (!datasetTypes.includes(type)) {
      appContext.logger.log(
        new AppError(
          `type of controller MUST be one of ${datasetTypes} but is ${type}`,
        ),
      )
    }

    const dataset = defaults(
      {
        collectionName: collectionId,
        filter,
        sort,
        includes,
        nested,
        pageSize,
        readWriteType,
        cursor,
        deferred,
      },
      defaultDatasetConfiguration,
      appContext.features.dropdownOptionsDistinct
        ? getFieldKeysForUniqueValues(connections)
        : {},
    )

    return {
      id,
      compId: id,
      type,
      livePreviewOptions: {
        shouldFetchData: dataIsInvalidated,
        compsIdsToReset: updatedCompIds,
      },
      connections,
      dynamicPageData,
      config: {
        dataset,
        datasetStaticConfig: getStaticDatasetConfig(dataset, type, connections),
      },
    }
  })
}

export default completeControllerConfigs
