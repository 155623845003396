import {
  get,
  isPlainObject,
  flow,
  map,
  pickBy,
  uniq,
  mergeWith,
  has,
} from 'lodash-es'
import { FIELD_PATH_SEPARATOR } from '@wix/dbsm-common/src/reference-fields/fieldPath'
import { FieldType } from '@wix/wix-data-schema-types'

const isReferenceValue = value => isPlainObject(value) && has(value, '_id')

const mergeReferences = (firstValue, secondValue) => {
  const preserveIncludedReferenceValue =
    firstValue !== secondValue &&
    isReferenceValue(firstValue) &&
    !isReferenceValue(secondValue)
  return preserveIncludedReferenceValue ? firstValue : secondValue
}

const addItemsToCollection = (items, collection = {}) =>
  items.reduce((acc, record) => {
    const existingRecord = acc[record._id]
    acc[record._id] = mergeWith(existingRecord, record, mergeReferences)

    return acc
  }, collection)

const convertDataToDataBindingContract = ({
  items,
  totalCount,
  collectionId,
}) => ({
  recordInfosInDatasetOrder: [
    {
      itemIds: items.map(({ _id }) => _id),
      totalCount,
      collectionId,
    },
  ],
  recordsByCollectionId: { [collectionId]: addItemsToCollection(items, {}) },
})

const getFieldType = (schema, fieldParts) => {
  const fieldTypePath = fieldParts
    .flatMap(namePart => ['fields', namePart])
    .concat('type')

  return get(schema, fieldTypePath)
}

const firstFieldPartIsReference = (schema, fieldParts) =>
  fieldParts.length >= 2 &&
  getFieldType(schema, [fieldParts[0]]) === FieldType.reference

const getFieldTypeCreator = (schema, relatedSchemas) => fieldName => {
  const fieldParts = fieldName.split(FIELD_PATH_SEPARATOR)
  if (firstFieldPartIsReference(schema, fieldParts)) {
    const [referenceFieldName, ...referencedFieldParts] = fieldParts
    const referencedCollectionName =
      schema && schema.fields[referenceFieldName]
        ? schema.fields[referenceFieldName].referencedCollection
        : null
    const referencedSchema =
      relatedSchemas && referencedCollectionName
        ? relatedSchemas[referencedCollectionName]
        : null

    return getFieldType(referencedSchema, referencedFieldParts)
  }
  return getFieldType(schema, fieldParts)
}

const getReferencedCollectionIds = schema => {
  return schema
    ? flow(
        fields =>
          pickBy(fields, ({ referencedCollection }) =>
            Boolean(referencedCollection),
          ),
        references =>
          map(references, ({ referencedCollection }) => referencedCollection),
        uniq,
        uniqueCollectionIds => uniqueCollectionIds.filter(Boolean),
      )(schema.fields)
    : []
}

const getFieldReferencedCollection = (fieldName, schema) =>
  schema != null && schema.fields[fieldName] != null
    ? schema.fields[fieldName].referencedCollection
    : null

const getSchemaDisplayField = schema =>
  schema != null ? schema.displayField : null

const getSchemaMaxPageSize = schema =>
  schema != null ? schema.maxPageSize : undefined

export {
  mergeReferences,
  convertDataToDataBindingContract,
  getFieldTypeCreator,
  getReferencedCollectionIds,
  getFieldReferencedCollection,
  getSchemaDisplayField,
  getSchemaMaxPageSize,
}
